/* You can add global styles to this file, and also import other style files */
.text-main {
  color: #00aca2;
}

@import url('./assets/styles/global.scss');
@import url('./assets/styles/classes/classes.scss');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: var(--background-color);
}

a {
  text-decoration: none !important;
}

.logo {
  color: var(--color-primary);
  font-family: Roboto !important;
  font-size: 32px !important;
  font-weight: 900 !important;
  text-transform: capitalize;
  cursor: pointer;
}

.user-avatar-name {
  background-color: var(--color-success-light);
  color: var(--color-primary);
  border-radius: 50%;
  width: 96px;
  height: 96px;
  font-size: 2rem;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.profile {
  min-height: 1300px;

  .profile-info {
    position: absolute;
    width: 1113px;
    left: 50%;
    top: 225px;
    transform: translateX(-50%);

    border-radius: 16px;
    border: 1px solid var(--gray-stroke, #D2D2D2);
    background: #FFF;
    box-shadow: 4px 12px 24px 0px rgba(0, 0, 0, 0.06);

    @media (max-width: 767px) {
      width: 100%;
      border: none;
      top: 0;
      position: relative;
      box-shadow: unset;
    }

    @media (min-width: 768px) {
      position: relative;
      top: 100px;
      width: 90%;
    }

    @media (min-width: 1191px) {
      width: 1140px;
    }

  }

}

@media (max-width: 767px) {
  .profile {
    min-height: 1500px;
  }
}


// status label
.status {
  padding: 10px 15px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    padding: 6px 10px;
    font-size: 14px;
  }

  i {
    font-size: .8rem;
  }

  &.success {
    background: var(--color-success-light);
    color: var(--color-success) !important;
  }

  &.danger {
    background: var(--color-danger-light);
    color: var(--color-danger) !important;;
  }

  &.pending {
    background: var( --color-warning-light);
    color: var(--color-warning) !important;
  }

  &.canceled {
    background: var(--status-canceled-background);
    color: #000 !important;
  }

  &.completed {
    background: var(--status-completed-background);
    color: #000 !important;
  }

  &.processing {
    background: #61FF53;
    color: #0A7D00 !important;
  }

  &.returned {
    background: #FFA9F6;
    color: #7A006E !important;
  }

  &.paid {
    background: #95FF70;
    color: #000 !important;
  }

  &.failed {
    background: rgba(249, 180, 180, 0.47);
    color: #FB3737 !important;
  }

  &.on-hold {
    background: #E6FF4A;
    color: #764700 !important;
  }

  &.resolved {
    background-color: rgb(0, 191, 255);
    color: #000 !important;
  }


}


// toast alert on mobile
.p-toast-top-right {
  top: 95px;
  @media (max-width: 767px) {
    width: 100%;
    left: 0px;
    right: 30px;
  }
}

// accordion styles
.mobile-accordion-view {
  .accordion-button {
    background-color: white;
    font-weight: 500;
  }
  .accordion-body {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 160% */
    text-transform: capitalize;
    p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      color: var(--color-primary);
      span {
        color: var(--color-secondary);
      }
    }
  }
}

.new-ticket-button {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 13px;
  border-radius: 8px;
}


.loader-nav {
  z-index: 60;
  animation: fadeOut .5s forwards 2s;

  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  i {
    font-size: 26px;
    color: var(--color-primary);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    display: none;
  }
}

// alert of p-message prime ng inside .alert-message container only
.alert-message {

  .p-message-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p-message-summary {
      display: none;
    }
  }

}

.ng-text {
  background: linear-gradient(to right, #fc72ff, #8f68ff, #487bff, #8f68ff, #fc72ff);
  background-size: 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate-gradient 2.5s linear infinite;
}

@keyframes animate-gradient {
	to {
		background-position: 200%;
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
