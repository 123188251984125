:root {
  --color-white: #FFF;
  --color-primary: #00ACA2;
  --color-secondary: #002D2A;
  --color-gray: #757575;
  --color-gray-2: #E4E4E7;
  --color-gray-outline: #D2D2D2;
  --color-light-green: #B9FFFD;
  --color-success: #11D900;
  --color-success-light: #E8FFE6;
  --color-danger: #FF4242;
  --color-bg-danger: rgba(252, 156, 156, 0.50);
  --color-icon-danger: #DB2020;
  --color-text-danger: #AC0000;
  --color-danger-light: #FFEDED;
  --color-warning: #F58700;
  --color-warning-light: #FFF6DE;

  --color-font-icon: #B7EACC;
  --color-solid-border: rgba(3, 89, 13, 0.83);

  --status-canceled-background: #AEAEAE;
  --status-completed-background: #a0f4ff;

  --border-radius-1: 8px;
  --border-radius-2: 16px;


  --box-shadow-1: 0px 10px 16px 0px rgba(0, 172, 162, 0.20);
  --box-shadow-2: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
  --box-shadow-3: 4px 12px 24px 0px rgba(0, 0, 0, 0.06);

  --transition-main: all 0.3s ease 0s;
}
