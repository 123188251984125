.head-title {
  overflow: hidden;
  padding: 5px 0;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    margin-bottom: 0px !important;
  }

  h3 {
    padding: 1rem 0;
    color: var(--color-secondary);
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    z-index: 3;
    line-height: 2.5rem;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px; /* 133.333% */
      text-transform: capitalize;
    }
  }

  span {
    content: "";
    position: absolute;
    top: 42%;
    left: 50%;
    width: 188px;
    height: 37px;
    background-color: var(--color-light-green);
    border-radius: 3px;
    z-index: 2;
    @media (max-width: 767px) {
      height: 19px;
    }
  }
}

// start component details

.data-preview {
  border-radius: 16px;
  border: 1px solid var(--color-gray-outline);
  background: #fff;
  box-shadow: 4px 12px 24px 0px rgba(0, 0, 0, 0.06);

  h3 {
    font-size: 16px;
    min-height: 40px;
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  .data-info {
    label {
      color: var(--color-secondary);
      font-family: Roboto;
      font-size: 16px;
    }

    label,
    h3 {
      font-weight: 500;
    }
  }

  .btn-main {
    border-radius: 8px;
  }

  .from-to-directions {
    border-radius: 12px;
    border: 1px solid var(--color-gray-outline);
    background: #fff;
    @media (width <= 767px) {
      width: 90%;
    }
  }

  .inner {
    margin: auto;
    overflow-wrap: break-word;
  }

  .create-ticket {
    width: 220px;
    height: 56px;
    line-height: 56px;
    padding: 0 !important;
    i {
      font-size: 1.2rem;
    }
  }
}
