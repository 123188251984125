// common variables for widths, heights, and breakpoints
$dialog-border-radius: 16px;
$dialog-width-desktop: 600px;
$dialog-padding: 32px;
$dialog-icon-size: 52px;
$primary-color: var(--color-primary);
$text-secondary-color: var(--text-secondary);

@mixin responsive-width($width) {
  width: $width;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.dialog {
  .p-dialog, .p-dropdown-label {
    border-radius: $dialog-border-radius;
    overflow: hidden;
    border: none;
  }

  &.main-home-dialog.request-new-method {
    .p-dialog {
      width: $dialog-width-desktop;
    }
  }

  &.main-home-dialog {
    .p-dialog-header {
      padding: 40px $dialog-padding 20px;
      border-bottom: none;
    }

    .p-dialog-header-icons {
      border: 2px solid $primary-color;
      border-radius: 50%;
      color: $primary-color;
      transition: 0.2s ease;

      &:hover {
        color: var(--color-white);
        background-color: $primary-color;

        .p-dialog-header-icon {
          color: var(--color-white);
        }
      }

      .p-dialog-header-icon {
        color: $primary-color;
        border-radius: 50%;
      }
    }

    .p-dialog-content {
      padding: 0 $dialog-padding;
    }

    .submit-btn {
      height: 48px;
      line-height: 48px;
    }
  }

  label.wide-label {
    width: 150px;
  }

  p-dropdown {
    @include responsive-width(100%);
  }

  .wideInput {
    @include responsive-width(270px);

    .p-inputnumber-input {
      @include responsive-width(270px);
    }
  }

  .p-dialog-header {
    .inline-flex {
      display: block;
      margin: auto;
      color: $text-secondary-color;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }

  &.new-methods-dialog {
    @media (max-width: 767px) {
      .p-dialog {
        width: auto;
      }
    }

    @media (min-width: 1200px) {
      .p-dialog {
        width: 75vw;
      }
    }

    @media (min-width: 1400px) {
      .p-dialog {
        width: 80vw;
      }
    }

    .search-input {
      height: $dialog-icon-size;
    }

    .other-methods {
      gap: 14px;
      height: 320px;
    }

    .method-name {
      color: var(--text, #002D2A);
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      height: inherit;
      line-height: 20px;
    }

    h2.method-title {
      color: #2A2A2A;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .method-type-list {
      cursor: pointer;
      height: auto;
      padding: 14px 0;
      line-height: 65px;
      border-radius: 6px;
      border: 2.5px solid #B8B8B8;
      background: #FFF;
      transition: border 0.3s ease;
      max-height: 55px;

      &.selected {
        border-color: var(--color-solid-border);
        background: var(--color-font-icon);
        position: relative;
        transition: .3s linear;

        &::before {
          content: '\f00c';
          font-family: 'Font Awesome 6 Free';
          position: absolute;
          top: 5px;
          right: 5px;
          font-weight: 900;
          color: var(--color-font-icon);
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: var(--color-solid-border);
          animation: scaleUp 0.4s linear forwards;
        }
      }
    }

    @keyframes scaleUp {
      0% { transform: scale(0.8); }
      50% { transform: scale(1); }
      75% { transform: scale(0.9); }
      100% { transform: scale(1); }
    }

    .submit-search {
      width: $dialog-icon-size;
      height: $dialog-icon-size;
    }

    .done-button {
      display: flex;
      width: 164px;
      height: 56px;
      padding: 16px 0;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: $primary-color;
    }
  }
}
