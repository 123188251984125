
.profile-wrapper {
  height: 380px;
  background: url('https://money-exchange-app.s3.eu-central-1.amazonaws.com/currencies/3c515014-2011-4679-8649-9eeda4861edd.png');
  background-size: cover;

  @media (max-width: 767px) {
    background: white;
  }
}
