.btn-main {
  padding-block: 10px;
  border: 0;
  outline: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  font-weight: 700;

  font-family: Roboto;
  font-size: 18px;


  &.primary {
    background: var(--color-primary);
    color: var(--color-white);
  }

  &.primary-outline {
    background: var(--color-white);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  &.simple {
    color: var(--color-primary);
    background: transparent;
  }

  &.green {
    border-radius: 4px;
    border: 1px solid var(--color-primary);
    background: rgba(0, 172, 162, 0.04);

    color: var(--color-primary);
    font-weight: 600;
    height: 90px;
    line-height: 90px;
    padding: 0;
  }

  &.danger {
    border-radius: 4px;
    border: 1px solid var(--color-icon-danger);
    background: rgba(219, 32, 32, 0.08);

    color: var(--color-icon-danger);
    font-weight: 600;
    height: 90px;
    line-height: 90px;
    padding: 0;
  }

  &.logout {
    border-radius: 8px;
    border: 1px solid var(--color-primary);
    background: transparent;
    width: 233px;
    color: var(--color-primary);
    font-weight: 600;
    height: 56px;
    line-height: 56px;
    padding: 0;

    @media (max-width: 992px) {
      width: 100%;
      height: 48px;
    }
  }

  &.rounded-main {
    border-radius: 8px;
  }

  &.btn-select-currency {
    height: 48px;
    width: 300px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
  }
}

@media (max-width: 991px) {
  .btn-main {
    font-size: 14px;
  }
}

.btn-link {
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary) !important;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
