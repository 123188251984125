.form-app-main {
  border-radius: var(--border-radius-2);
  border: 1px solid var(--color-gray-outline);
  background: var(--color-white);
  box-shadow: var(--box-shadow-3);

  h3 {
    color: var(--color-primary);
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 50px;
  }

  .desc-main {
    color: var(--color-gray);
    font-family: Cairo;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

}

.input-main-icon {
  & > span {
    width: 100%;

    .pi {
      color: var(--color-primary);
    }

    .p-inputtext {
      width: 100%;
      border-radius: 8px;
      background: #ECECEC;
      border: 0;
      padding-block: 12px;

      &:enabled:focus {
        box-shadow: none;
      }
    }

  }

}

.input-main-groub {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  label, .label {
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
  }

  .p-inputtext {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-gray-2);
    padding-block: 13px;
    font-size: 14px;
    color: var(--color-secondary);
    background: transparent;

    &:enabled:focus {
      box-shadow: none;
      border: 1px solid var(--color-primary);
    }
  }

  .rounded-8 {
    border-radius: 8px;
  }

  .p-password {
    width: 100%;

    .p-icon-wrapper {
      color: var(--color-primary);
    }

    .p-inputtext {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--color-gray-2);
      padding-block: 13px;
      font-size: 14px;
      color: var(--color-secondary);
      background: transparent;

      &:enabled:focus {
        box-shadow: none;
        border: 1px solid var(--color-primary);
      }
    }
  }

  .wideInput {
    width: 100%;
    span.p-inputnumber {
      width: 100%;
      border-radius: 8px;
      .p-inputnumber-input {
        border-radius: 8px;
      }
    }

  }

  .inputDarkerBorder {
    span.p-inputnumber {
      .p-inputnumber-input {
        border-color: #BFBFBF;
      }
    }

  }

}

.input-second-group {

  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  label {
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 8px;
  }

  .p-inputtext {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-gray-2);
    padding-block: 13px;
    font-size: 14px;
    color: var(--color-secondary);
    background: transparent;

    &:enabled:focus {
      box-shadow: none;
      border: 1px solid var(--color-primary);
    }
  }


  .p-password {
    width: 100%;

    .p-icon-wrapper {
      color: var(--color-primary);
    }

    .p-inputtext {
      width: 100%;
      border-radius: 12px;
      border: 1px solid var(--color-gray-2);
      padding-block: 13px;
      font-size: 14px;
      color: var(--color-white);
      background: transparent;

      &:enabled:focus {
        box-shadow: none;
        border: 1px solid var(--color-primary);
      }
    }
  }
}

.dropdown-main {
  border-radius: 12px;

  .p-dropdown {
    border-radius: 8px;
    box-shadow: 4px 6px 16px 0px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 991px) {
    .p-dropdown {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .p-dropdown:not(.p-disabled).p-focus {
    border-color: var(--color-primary);
  }

  .p-dropdown-items {
    padding: 0;
    margin-bottom:0;
  }

  .p-dropdown-item {
    padding: 12px;
  }

  ::ng-deep .p-dropdown-label {
    border: none;
  }

  .p-inputtext {
    border: none;
    color: var(--color-secondary);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;

    height: 48px;
    line-height: 48px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

// start upload area
.upload-area-texts {
  color: var(--color-secondary);
  font: {
    size: 14;
    family: Roboto, 'sans serif';
    weight: 500;
  }
}

.upload-area {
  // parent of upload file input
  .input-file {
    padding: 20px 25px;
    border-radius: 12px;
    border: 1px dashed #BFBFBF;

    @media (width <=512px) {
      justify-content: center !important;
    }

    // button of select file
    button {
      border-radius: 4px;
      background: #DCFFFF;
      border: none;
      outline: none;
      color: #00ACA2;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      @media (width <= 512px) {
        width: 100%;
      }

      // this is input
      input {
        cursor: pointer;
        transform: scale(3);
      }
    }

    // text of area of file uploader
    .text {
      p {
        @extend .upload-area-texts;
      }

      small {
        @extend .upload-area-texts;
        font-size: 11px;
        font-weight: 400;
        color: #757575
      }

      @media (width <=512px) {
        text-align: center;
        display: block !important;

        i svg {
          margin-bottom: 15px;
        }

        small {
          font-size: 8;
        }
      }
    }
  }

  // for uploaded file
  .uploaded-file {
    p {
      @extend .upload-area-texts;
      font-size: 12px;
      font-weight: 400;
    }

    small {
      @extend .upload-area-texts;
      color: #757575;
      font-size: 10px;
      font-weight: 400;
    }



  }
}

// end upload area

// special treatment for dropdown in wallets page
// because of the appendTo
body {
  .p-dropdown-items {
    padding: 0;
    margin-bottom:0;
  }
}


// styling of the confirmation message panel
p-button[data-label="yes"] {
  button {
    border-radius: 6px;
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
    color: var(--color-white);
    margin-right: 10px;
  }
}

p-button[data-label="no"] {
  button {
    border-radius: 6px;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: transparent;


  }

  :hover {
    background: var(--color-primary);
    color: var(--color-white);
  }
}

p-button[data-label="no-icon"] {
  button {
    border-radius: 6px;
    border: 1px solid transparent;
    color: var(--color-danger);
    background-color: transparent;
  }
}





