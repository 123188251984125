// dropdown of paginator
p-paginator p-dropdown {
  display: none;
}

// pages number
p-paginator .p-paginator {
  gap: 10px;
}

// first and last buttons
p-paginator .p-paginator-last,
p-paginator .p-paginator-first {
  display: none;
}

p-paginator button {
  border: none;
  margin: 0 5px;
  color: var(--color-primary);
  text-align: center;
  font-family: Cairo, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: transparent;
}

p-paginator button:hover {
  border-radius: 4px;
}

p-paginator .p-paginator-pages .p-highlight {
  color: #FFF;
  background-color: var(--color-primary);
  border-radius: 4px;
}

p-paginator .p-paginator-next,
p-paginator .p-paginator-prev {
  font-size: 14px;
}

@media (width <=512px) {

  p-paginator .p-paginator-next,
  p-paginator .p-paginator-prev {
    display: none;
    ;
  }
}
