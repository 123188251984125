.faqs {

  .faq-center {
    height: 600px;
  }

  .accordion {

    @media (max-width: 573px) {
      .accordion-button {
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    .accordion-button::after {
      content: "\f282";
      font-family: "bootstrap-icons";
      background-color: var(--color-primary);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-image: none;
      color: white;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
        font-size: 1rem;
      }

    }


    .number-head {
      color: var(--Text-txt-tertiary, #3c3c4380);
      font-family: 'Inter', sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 25.2px;
      width: 40px;
    }

    .question {
      color: var(--color-secondary);
      font-family: 'Inter', sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 33.6px;
      max-width: 75%;
      @media (max-width: 767px) {
        font-size: 14px;
        font-weight: 700;
        margin-right: 15px;
        line-height: normal;
        word-wrap: break-word;
      }
    }

    .accordion-collapse {
      background-color: #F2FDFC;

      .accordion-body {
        margin-left: 60px;
        p {
          color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
          font-family: 'Inter', sans-serif;
          line-height: 25.2px;
          font-size: 18px;
          font-weight: 400;
        }

        @media (max-width: 767px) {
          margin-left: 00px;
        }
      }
    }

    .accordion-header {
      margin: 0;
    }

    .accordion-button {
      background-color: #F2FDFC;
      box-shadow: none;
    }
  }

}

.para-info {
  color: #404040;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}
